// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["HCO0j5VIs"];

const variantClassNames = {HCO0j5VIs: "framer-v-oyiyrc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string; link?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "HCO0j5VIs", title: GvMsg1zox = "Design", link: EvBLinydd, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "HCO0j5VIs", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-lZU8T", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<Link href={EvBLinydd} smoothScroll><motion.a {...restProps} className={`${cx("framer-oyiyrc", className)} framer-1men6kt`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"HCO0j5VIs"} ref={ref} style={{backgroundColor: "rgb(234, 240, 244)", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-font-size": "13px", "--framer-line-height": "1em", "--framer-text-color": "var(--extracted-r6o4lv)", "--framer-text-transform": "capitalize"}}>Design</motion.p></React.Fragment>} className={"framer-j39psz"} layoutDependency={layoutDependency} layoutId={"lGm5gJGMA"} style={{"--extracted-r6o4lv": "rgb(24, 130, 208)", "--framer-paragraph-spacing": "0px"}} text={GvMsg1zox} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-lZU8T [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-lZU8T * { box-sizing: border-box; }", ".framer-lZU8T .framer-1men6kt { display: block; }", ".framer-lZU8T .framer-oyiyrc { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 5px 8px 5px 8px; position: relative; text-decoration: none; width: min-content; will-change: transform; }", ".framer-lZU8T .framer-j39psz { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-lZU8T .framer-oyiyrc { gap: 0px; } .framer-lZU8T .framer-oyiyrc > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-lZU8T .framer-oyiyrc > :first-child { margin-left: 0px; } .framer-lZU8T .framer-oyiyrc > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 23
 * @framerIntrinsicWidth 58.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"GvMsg1zox":"title","EvBLinydd":"link"}
 */
const Framerj4VfDKcYo: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default Framerj4VfDKcYo;

Framerj4VfDKcYo.displayName = "tag";

Framerj4VfDKcYo.defaultProps = {height: 23, width: 58.5};

addPropertyControls(Framerj4VfDKcYo, {GvMsg1zox: {defaultValue: "Design", displayTextArea: false, title: "Title", type: ControlType.String}, EvBLinydd: {title: "Link", type: ControlType.Link}} as any)

addFonts(Framerj4VfDKcYo, [])